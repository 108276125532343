import Cookies from 'js-cookie'

export default class Alert {
  constructor() {
    this.banner = $('.alert')
    this.bannerCookie = Cookies.get('crossroads-alert-dismiss')
    this.close = $('.alert__close')
    this.sessionData = this.banner.data('session')
    this.link = this.banner.find('a')

    this.close.on('click', () => {
      this.closeAlert()
    })

    if (this.bannerCookie && this.bannerCookie !== this.sessionData) {
      this.resetCookie()
    } else if (!this.bannerCookie) {
      this.showBanner()
    }

    this.link.on('click', (e) => {
      this.redirect()
    })
  }

  closeAlert() {
    Cookies.set('crossroads-alert-dismiss', this.sessionData, { expires: 1 })
    this.banner.slideUp(300)
  }

  resetCookie() {
    Cookies.remove('crossroads-alert-dismiss')
    this.banner.fadeIn(300)
  }

  showBanner() {
    this.banner.fadeIn(300)
  }

  redirect() {
    Cookies.set('crossroads-alert-dismiss', this.sessionData, { expires: 1 })
  }
}
