export default class Carousel {
  constructor() {
    const carousel = $('*[data-carousel="true"]');
    carousel.slick({
      prevArrow: $(".carousel__prev-button"),
      nextArrow: $(".carousel__next-button"),
      slidesToShow: 1,
      infinite: false,
      adaptiveHeight: true,
    });

    /** @type {HTMLElement[]} */
    const captions = Array.from(
      document.querySelectorAll('*[data-caption="true"')
    );

    carousel.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
      captions.forEach((caption, index) => {
        caption.dataset.active = index === nextSlide ? "true" : "false";
      });
    });
  }
}
