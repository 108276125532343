export default class Menu {
  constructor() {
    this.document = $(document)
    this.main = $('main')
    this.hamburger = $('.hamburger__wrapper')
    this.nav = $('.header__nav')
    this.searchToggle = $('.header__search__toggle')
    this.initMenu()
  }

  initMenu() {
    this.hamburger.on('click', () => {
      this.hamburger.children().toggleClass('active')
      this.nav.slideToggle(300)
      $('.header__search form').removeClass('active')
      this.searchToggle.removeClass('hide')
    })

    const childrenToggles = document.querySelectorAll('.children-toggle')

    Array.from(childrenToggles).forEach((toggle) => {
      toggle.addEventListener('click', () => {
        toggle.classList.toggle('open')
        $(toggle).next().slideToggle()
      })
    })

    $('.children-toggle').parents('li').addClass('has-children')

    this.searchToggle.on('click', () => {
      this.searchToggle.addClass('hide')
      $('.header__search form').addClass('active')
      $('#q').focus()
    })

    this.main.on('click', () => {
      this.hamburger.children().removeClass('active')
      $('.header__search form').removeClass('active')
      this.searchToggle.removeClass('hide')

      if (window.innerWidth < 960) {
        this.nav.slideUp(300)
      }
    })

    $(document).on('keydown', function(event) {
        if (event.key == "Escape") {
          $('.hamburger__wrapper').children().removeClass('active')
          $('.header__search form').removeClass('active')
          $('.header__search__toggle').removeClass('hide')
    
          if (window.innerWidth < 960) {
            $('.header__nav').slideUp(300)
          }
        }
    });
  }
}
