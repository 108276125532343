import Cookies from 'js-cookie'

export default class Popup {
  constructor() {
    this.container = $('.popup')
    this.containerCookie = Cookies.get('crossroads-popup-dismiss')
    this.close = $('.popup__close')
    this.background = $('.popup__background')
    this.document = $(document)

    this.sessionData = this.container.data('session')

    this.close.on('click', () => {
      this.closePopup()
    })
    
    this.background.on('click', () => {
      this.closePopup()
    })
    
    this.background.on('click', () => {
      this.closePopup()
    })

     // Remove the navigation if the esc is pressed
    this.document.on("keyup", (event) => {
        if (event.which === 27) {
            event.preventDefault()
            this.closePopup()
        }
    })

    if (this.containerCookie && this.containerCookie !== this.sessionData) {
      this.resetCookie()
    } else if (!this.containerCookie) {
      this.showBanner()
    }
  }

  closePopup() {
    Cookies.set('crossroads-popup-dismiss', this.sessionData, { expires: 1 })
    this.container.fadeOut(300)
  }

  resetCookie() {
    Cookies.remove('crossroads-popup-dismiss')
    this.container.fadeIn(300)
  }

  showBanner() {
    this.container.fadeIn(300)
  }

  redirect() {
    Cookies.set('crossroads-popup-dismiss', this.sessionData, { expires: 1 })
  }
}
