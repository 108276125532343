export default class ResourcesSlider {
  constructor() {
    $(".js-resources-block__card-grid").slick({
      dots: false,
      arrows: true,
      prevArrow: $(".resources-block__slider-prev-button"),
      nextArrow: $(".resources-block__slider-next-button"),
      infinite: false,
      slidesToShow: 1.05,
      mobileFirst: true,
      adaptiveHeight: false,
      centerMode: true,
      centerPadding: '50px',
      responsive: [
        {
          breakpoint: 767,
          settings: "unslick",
        },
      ],
    });
  }
}
