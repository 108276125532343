import "core-js/stable";
import "regenerator-runtime/runtime";
import objectFitImages from "object-fit-images";
import objectFitVideos from "object-fit-videos";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment/src/moment';
import 'fullcalendar';

objectFitImages();
objectFitVideos();

import Menu from './menu'
import Accordions from './accordions'
import slider from './sliders'
import Alert from './alert'
import Popup from './popup'
import PostFilter from './post-filter'
import NewsSlider from "./newsSlider";
import ResourcesSlider from "./resourcesSlider";
import Trainings from "./trainings";

new Menu()
new Accordions()
new slider()
new PostFilter()
new NewsSlider();
new ResourcesSlider();
new Alert()
new Popup()
new Trainings()

import Formbuilder from './formbuilder'
import Events from './events'
import Video from './video'
import Carousel from './carousel'

new Formbuilder();
new Events();
new Video();
new Carousel()
