export default class NewsSlider {
  constructor() {
    $(".news-cards__card-grid").slick({
      dots: false,
      arrows: true,
      prevArrow: $(".news-cards__slider-prev-button"),
      nextArrow: $(".news-cards__slider-next-button"),
      infinite: false,
      slidesToShow: 1.1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 767,
          settings: "unslick",
        },
      ],
    });
  }
}
