import Vimeo from '@vimeo/player'

export default class Sliders {
  constructor() {
    $('.home-hero__slides').slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 20,
      swipe: false,
      slidesToShow: 1,
      useCSS: true,
    })

    $('.home-hero__down').on('click', (event) => {
      const hero = event.currentTarget.parentNode
      const next = hero.nextElementSibling

      if (next) {
        window.scrollTo({
          top: next.offsetTop - 68,
          left: 0,
          behavior: 'smooth',
        })
      } else {
        window.scrollTo({
          top: $('.footer')[0].offsetTop - 68,
          left: 0,
          behavior: 'smooth',
        })
      }
    })

    const toggle = $('.home-hero__toggle')
    toggle.on('click', () => {
      if (toggle.hasClass('paused')) {
        toggle.removeClass('paused')
        $('.home-hero__slides').slick('slickPlay')
      } else {
        toggle.addClass('paused')
        $('.home-hero__slides').slick('slickPause')
      }
    })

    $('.home-hero__slides').on('beforeChange', (_, slick, before, after) => {
      const prevSlide = slick.$slides[before]
      const nextSlide = slick.$slides[after]

      const prevVideo = prevSlide.querySelector('video')
      const prevIframe = prevSlide.querySelector('iframe')
      const nextVideo = nextSlide.querySelector('video')
      const nextIframe = nextSlide.querySelector('iframe')

      if (prevVideo) {
        prevVideo.pause()
      } else if (prevIframe) {
        if (prevIframe.src.includes('youtube')) {
          new YT.Player(prevIframe.id, {
            events: {
              onReady: (event) => event.target.stopVideo(),
            },
          })
        } else if (prevIframe.src.includes('vimeo')) {
          const vimeoVideo = new Vimeo(prevIframe.id)
          vimeoVideo.pause()
        }
      }

      if (nextVideo) {
        nextVideo.play()
      } else if (nextIframe) {
        if (nextIframe.src.includes('youtube')) {
          new YT.Player(nextIframe.id, {
            events: {
              onReady: (event) => event.target.playVideo(),
            },
          })
        } else if (nextIframe.src.includes('vimeo')) {
          const vimeoVideo = new Vimeo(nextIframe.id)
          vimeoVideo.play()
        }
      }
    })

    $('.posts__slider').slick({
      variableWidth: true,
      infinite: false,
      dots: true,
      arrows: true,
    })

    // timeout ensures images load before slick is called.
    setTimeout(() => {
      $('.gallery__slides').slick({
        dots: true,
      })
    }, '1000')
  }
}
