export default class Trainings {
  constructor() {
    var submitButton = $(".register .submit-button button");
    if ($("#form_4").length > 0) {
      $("#text_field_14")[0].value = $(".post__title")[0].innerHTML;
    }
    $(document).ready(function () {
      if ($(".dates .button--secondary").length) {
        submitButton.prop("disabled", true);
      }
    });

    $(".dates button").on("click", function () {
      $(".dates button").removeClass("date--selected");
      $(this).addClass("date--selected");
      $(".error").fadeOut();
      submitButton.prop("disabled", false);

      var $sfID = $(".date--selected > .sf-info > .sfID")[0].innerHTML;
      if ($(".date--selected > .sf-info .sfName")[0].innerHTML.length > 0) {
        var $sfName = $(".date--selected > .sf-info > .sfName")[0].innerHTML;
      }
      if (
        $(".date--selected > .sf-info .sfCanonical")[0].innerHTML.length > 0
      ) {
        var $sfCanonical = $(".date--selected > .sf-info > .sfCanonical")[0]
          .innerHTML;
      }
      if ($(".date--selected > .sf-info .sfDate")[0].innerHTML.length > 0) {
        var $sfDate = $(".date--selected > .sf-info > .sfDate")[0].innerHTML;
      }

      $("#text_field_34")[0].value = $sfID;
      $("#text_field_35")[0].value = $sfName;
      $("#text_field_36")[0].value = $sfCanonical;
      $("#text_field_37")[0].value = $sfDate;
    });
  }
}
