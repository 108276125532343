export default class PostFilter {

  urlBuilder(){
    const multicatOption = $('#multicat-filter option:selected').val()
    const categoryOption = $('#category-filter option:selected').val()
    const multicattSlug = $('#multicat-filter option:selected').data('slug')
    const catSlug = $('#category-filter option:selected').data('slug')
    const slug = $('#posts-filter').data('slug')
    const search = $('#post-search').val()
    let url = '/' + slug
    let symbol = '?'

    if (typeof search != "undefined" && search.length > 0) {
      url = url + symbol + 'search=' + search
      symbol = '&'
    }

    if (typeof categoryOption != "undefined" && categoryOption != "all") {
      url = url + symbol + catSlug + '=' + categoryOption
      symbol = '&'
    }
    
    if (typeof multicatOption != "undefined" && multicatOption != "all") {
      url = url + symbol  + multicattSlug + '=' + multicatOption
      symbol = '&'
    }
    
    
    return url + "#cat"

  }

  constructor() {
    const search = $('#post-search-submit')
    const category = $('#category-filter')
    const multicat = $('#multicat-filter')

    if(search.length){
      search.on('click', (event) => {
        event.preventDefault()
        window.location = this.urlBuilder()
      })
    }

    if(category.length){
      category.on('change', (event) => {
        window.location = this.urlBuilder()
      })
    }

    if(multicat.length){
      multicat.on('change', (event) => {
        window.location = this.urlBuilder()
      })
    }
  }

}
