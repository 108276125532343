import "jquery-ujs";
import "./remotipart";
import "jquery-datetimepicker";
import "jquery-datetimepicker/jquery.datetimepicker.css";

export default class Formbuilder {
  constructor() {
    $(".new_form_response").on("submit", function (event) {
      $(event.target).find('button[type="submit"]').prop("disabled", true);
    });

    $(".new_form_response").on("ajax:success", function (event, data) {
      $('.new_form_response button[type="submit"]').prop("disabled", false);

      if (!data.form) {
        $(event.currentTarget).replaceWith(data.payload);
      } else {
        const form = data.form.form;

        if (form.page_redirect) {
          window.location.href = form.redirect_path;
        } else {
          $(event.currentTarget).replaceWith(data.payload);
        }
      }
    });

    $(".new_form_response").on("ajax:error", function () {
      alert("something went wrong. Please try again or contact support team");
      $('.new_form_response button[type="submit"]').prop("disabled", false);
    });

    this.initForms();
  }

  initForms() {
    $(".date-input").datetimepicker({
      timepicker: false,
      scrollMonth: false,
      scrollInput: false,
      format: "F j, Y",
      formatTime: "g:i a",
    });

    $(".time-input").datetimepicker({
      datepicker: false,
      scrollMonth: false,
      scrollInput: false,
      format: "g:i a",
      formatTime: "g:i a",
      step: 15,
    });
  }
}
